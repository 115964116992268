import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/apiService";

export const updateCredit = createAsyncThunk("get/customers",
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.fetchSingleCustomer(payload.idNumber, payload.apiKey);
            console.info(`Details: ${JSON.stringify(payload)}`)
            if (response.data.status === true) {
                let dataReponse = response.data.data
                if (dataReponse.length === 1) {
                    const response2 = await apiService.updateAmtCredit(dataReponse[0].id, { credit: payload.credit }, payload.apiKey);
                    if (response2.data.status === true) {
                        return { status: true, message: response2.data.data }
                    } else {
                        return { status: false, message: response2.data.err }
                    }
                } else {
                    return { status: false, message: 'No Customer Info found. Try again later' }
                }
            } else {
                return { status: false, message: 'No Customer Info found. Try again later' }
            }
        } catch (error) {
            console.error(`api service error: ${error}`)
            return { status: false, message: error.response.status === 403 ? 'Invalid API Key. Please try again later' : 'Something went wrong, Try after sometime.' }
        }
    }
);

const initialState = { isLoading: false }

const updateCreditSlice = createSlice({
    name: "auth",
    initialState,
    extraReducers: {
        [updateCredit.fulfilled]: (state, action) => {
            state.isLoading = false;
        },
        [updateCredit.rejected]: (state, action) => {
            state.isLoading = false;
        },
    },
});

const { reducer } = updateCreditSlice;
export default reducer;