import React from "react";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const XProgress = (props) => {

    return (
        <Grid container spacing={2}>
            <Grid item xs>
                <Box sx={{ width: '100%', padding: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    <CircularProgress thickness={1} /> <div data-testid="please-wait" style={{ marginLeft: '30px' }} color="primary">{props.message}</div>
                </Box>
            </Grid>
        </Grid>
    )
}

export default XProgress