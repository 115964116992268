import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import { setStatusCode } from "./statusCode";
import apiService from "../services/apiService";

const score = JSON.parse(localStorage.getItem("score"));

export const register = createAsyncThunk("auth/register",
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.register(payload);
            thunkAPI.dispatch(setMessage(response.data.score));
            thunkAPI.dispatch(setStatusCode(response.status))
            return response;
        } catch (error) {
            thunkAPI.dispatch(setMessage(error.response.data.detail.toString()));
            thunkAPI.dispatch(setStatusCode(error.response.status))
            return thunkAPI.rejectWithValue();
        }
    }
);

const initialState = score
    ? { isLoading: false, score }
    : { isLoading: true, score: null };

const scoreSlice = createSlice({
    name: "auth",
    initialState,
    extraReducers: {
        [register.fulfilled]: (state, action) => {
            state.isLoading = false;
        },
        [register.rejected]: (state, action) => {
            state.isLoading = false;
        },
    },
});

const { reducer } = scoreSlice;
export default reducer;