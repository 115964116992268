import React from "react";
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const XCreditScore = (props) => {
    const { t } = useTranslation();

    return (
        <Grid container spacing={2} mt={1} mb={1}>
            <Grid item xs>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <CardContent sx={{ flexGrow: 1 }}>
                        <Typography gutterBottom>{t('c.credit.score')}</Typography>
                        <Typography variant="h6" component="h6">
                            {props.message}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs>
                <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <CardContent sx={{ flexGrow: 1 }}>
                        <Typography>
                            {t('api.response')}
                            {' '}
                            <br />
                            {`{{HTTP STATUS CODE: ${props.statuscode}}}`}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}

XCreditScore.propTypes = {
    message: PropTypes.string,
    statuscode: PropTypes.string
};

export default XCreditScore