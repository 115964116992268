import axios from "axios";
const API_URL = "https://maarifa.sunculture.io/api/"
const AMT_API_URL = "https://api.sunculture.io/prod/"

const register = (payload) => {
    return axios.post(`${API_URL}credit-score`, payload);
};

const fetchSingleCustomer = (idNumber, apiKey) => {
    return axios.get(`${AMT_API_URL}customers/${idNumber}`, {
        headers: {
            'x-api-key': apiKey,
            'Content-Type': 'application/json'
        }
    })
}

const updateAmtCredit = (id, payload, apiKey) => {
    return axios.put(`${AMT_API_URL}customers/credit/${id}`, payload, {
        headers: {
            'x-api-key': apiKey,
            'Content-Type': 'application/json'
        }
    })
}

const scoreService = {
    register,
    fetchSingleCustomer,
    updateAmtCredit
};

export default scoreService;