export const resources = {
    en: {
        translation: {
            'credit.title': 'Maarifa CreditScore CheckList',
            'credit.decision.title': 'Maarifa CreditScore Decision',
            'credit.security': 'SECURITY',
            'select.language': 'SELECT LANGUAGE',
            'button.reset': 'RESET',
            'input.enter.api.key': 'Enter API key provided by Sunculture admin',
            'customer.survey': 'SURVEY',
            'name': 'Your Name',
            'username': 'Your Username',
            'id.number': 'ID number',
            'gender': 'Gender',
            'dob': 'Date Of Birth',
            'input.family.occupation': 'Main occupation of the head of the family',
            'label.source.income': 'What is your Source of income',
            'label.depend.income': 'How long have you been depending on the above source of income?',
            'label.risk.assessment': 'What is the Overall Risk Assessment by Relationship Manager?',
            'label.pay.suppliers': 'What percentage of Kenyans do you think pay their suppliers on time?',
            'label.select.watersource': 'Select your WaterSource?',
            'label.own.waterpump': 'Did you previously own a water pump?',
            'label.has.animals': 'Do you have an animals',
            'label.type.crops': 'What type of crops do you grow?',
            'label.type.fruits': 'What type of fruits and vegetables do you grow?',
            'label.harvest.cycle': 'How many harvest cycles per year?',
            'label.hear.sc': 'How did you hear about SunCulture\'s products?',
            'label.pensioner': 'Are you a pensioner?',
            'label.members.household': 'How many members does this household have?',
            'label.pensioners.household': 'How many pensioners live in the household?',
            'label.working.age.adults': 'How many working age adults live in the household?',
            'button.submit.score': 'SUBMIT AND VIEW SCORE',
            'header.credit.score': 'Maarifa Credit',
            'c.credit.score': 'Credit Score',
            'api.response': 'API Response',
            'please.wait': 'Please wait. We are calculating the score',
            'page.not.found': 'Page not found!',
            'please.go.back': 'Please Go Back',
            'yes': 'Yes',
            'no': 'No',
            'radio.self.employed': 'Self-employed individuals',
            'radio.consumer.client': 'Consumer client',
            'cows': 'Cows',
            'goats': 'Goats',
            'pigs': 'Pigs',
            'sheep': 'Sheep',
            'reference.number': 'Account Reference Number',
            'button.submit.update.amt': 'Submit and Update AMT',
            'label.form.credit.decision': 'Credit Decision (LOV: false/true)',
            'please.wait.update.credit': 'Please wait. We are your updating credit score...',
            'button.exit': 'Go Back',
            'apikey': 'Enter the API Key',
            'enter.id.number': 'Enter Customer\'s ID Number',
            'btn.continue.score': 'Continue to credit scoring'
        },
    },
    fr: {
        translation: {
            'credit.title': 'Liste de contrôle Maarifa CreditScore',
            'credit.decision.title': 'Maarifa CreditScore Décision',
            'credit.security': 'SÉCURITÉ',
            'select.language': 'CHOISIR LA LANGUE',
            'button.reset': 'RÉINITIALISER',
            'input.enter.api.key': 'Entrez la clé API fournie par l\'administrateur Sunculture',
            'customer.survey': 'ENQUÊTE',
            'name': 'Votre Nom',
            'username': 'Votre nom d\'utilisateur',
            'id.number': 'Numéro d\'identification',
            'gender': 'Le genre',
            'dob': 'Date de naissance',
            'input.family.occupation': 'Occupation principale du chef de famille',
            'label.source.income': 'Quelle est votre source de revenu',
            'label.depend.income': 'Depuis combien de temps dépendez-vous de la source de revenu ci-dessus?',
            'label.risk.assessment': 'Qu\'est-ce que l\'évaluation globale des risques par le chargé de clientèle?',
            'label.pay.suppliers': 'Selon vous, quel pourcentage de Kenyans paient leurs fournisseurs à temps?',
            'label.select.watersource': 'Sélectionnez votre source d\'eau?',
            'label.own.waterpump': 'Possédiez-vous auparavant une pompe à eau?',
            'label.has.animals': 'Avez-vous des animaux ?',
            'label.type.crops': 'Quel type de cultures cultivez-vous?',
            'label.type.fruits': 'Quel type de fruits et légumes cultivez-vous?',
            'label.harvest.cycle': 'Combien de cycles de récolte par an?',
            'label.hear.sc': 'Comment avez-vous entendu parler des produits?',
            'label.pensioner': 'Êtes-vous un retraité?',
            'label.members.household': 'Combien de membres ce ménage compte-t-il?',
            'label.pensioners.household': 'Combien de retraités vivent dans le ménage?',
            'label.working.age.adults': 'Combien d\'adultes en âge de travailler vivent dans le ménage?',
            'button.submit.score': 'SOUMETTRE ET VOIR LE SCORE',
            'header.credit.score': 'Crédit Maarifa',
            'c.credit.score': 'Pointage de crédit',
            'api.response': 'Réponse API',
            'please.wait': 'S\'il vous plaît, attendez. Nous calculons le score',
            'page.not.found': 'Page non trouvée!',
            'please.go.back': 'Veuillez revenir en arrière',
            'yes': 'Oui',
            'no': 'Non',
            'radio.self.employed': 'Indépendants',
            'radio.consumer.client': 'Client consommateur',
            'cows': 'Vaches',
            'goats': 'Chèvres',
            'pigs': 'Les cochons',
            'sheep': 'Brebis',
            'reference.number': 'Numéro de référence du compte',
            'button.submit.update.amt': 'Soumettre et mettre à jour l\'AMT',
            'label.form.credit.decision': 'Décision de crédit (LOV: faux/vrai)',
            'please.wait.update.credit': 'S\'il vous plaît, attendez. Nous sommes votre cote de crédit mise à jour...',
            'button.exit': 'Retourner',
            'apikey': 'Entrez la clé API',
            'enter.id.number': 'Entrez le numéro d\'identification du client',
            'btn.continue.score': 'Continuer vers la notation de crédit'
        },
    },
};