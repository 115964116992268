import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './app/App';
import history from './history';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store'
import { I18nextProvider } from "react-i18next";
import i18next from "./helpers/i18n"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router history={history}>
    <React.StrictMode>
      <Provider store={store}>
        <I18nextProvider i18n={i18next}>
          <App />
        </I18nextProvider>
      </Provider>
    </React.StrictMode>
  </Router>
);

reportWebVitals();
