import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    showDialog: false
};

const showDialogSlice = createSlice({
    name: "showDialog",
    initialState,
    reducers: {

        setShowDialog: (state, action) => {
            console.log("setShowDialog: ", action.payload)
            return { showDialog: action.payload }
        }
    },
});

const { reducer, actions } = showDialogSlice;

export const { setShowDialog } = actions

export default reducer