import { createTheme } from '@mui/material/styles';
export const THEME = createTheme({
    typography: {
        "fontFamily": 'Work Sans',
        "fontSize": 14
    },
    button: {
        "fontFamily": 'Work Sans',
        "fontSize": 14
    },
    MuiButton: {
        styleOverrides: {
            root: {
                "fontFamily": 'Work Sans',
                "fontSize": 14,
            }
        }
    },
    overrides: {
        MuiButton: {
            root: {
                "fontFamily": 'Work Sans',
                "fontSize": 44,
            }
        },
        MuiFormControlLabel: {
            label: {
                "fontFamily": 'Work Sans',
                "fontSize": "10px"
            }
        },
        MuiInputLabel: {
            root: {
                color: 'black',
                fontSize: 13,
            },
        },
    },
});