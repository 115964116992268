export const watersources = [
    'Rainwater Harvest',
    'Well',
    'River',
    'Borehole',
    'Tap'
];

export const genders = [{
    value: 'Male',
    label: 'Male'
}, {
    value: 'Female',
    label: 'Female'
}]

export const sources = [
    'SunCulture Event',
    'Facebook',
    'Sales Agent',
    'Flyer',
    'Radio',
    'Friend, family member or neighbor',
    'Online Advertising',
    'SunCulture Website',
    'TV',
    'Other'
]

export const occupations = [{
    value: 'Company_Employee',
    label: 'Company/Business Employee'
}, {
    value: 'Government_Employee',
    label: 'Government Employee'
}, {
    value: 'Self_Employee',
    label: 'Sales Agent'
}, {
    value: 'Retired',
    label: 'Retired'
}, {
    value: 'Pension',
    label: 'Pension'
}, {
    value: 'Other',
    label: 'Other'
}]

export const ownPump = [{
    value: 'YES',
    label: 'YES'
}, {
    value: 'NO',
    label: 'NO'
}]

export const risks = [{
    value: 'Very Low Risk',
    label: 'Very Low Risk'
}, {
    value: 'Low Risk',
    label: 'Low Risk'
}, {
    value: 'Average Risk',
    label: 'Average Risk'
}, {
    value: 'High Risk',
    label: 'High Risk'
}]

export const perceptions = [{
    value: '_10',
    label: '<10%'
}, {
    value: '10_30',
    label: '10% - 30%'
}, {
    value: '30_50',
    label: '30% - 50%'
}, {
    value: '50_60',
    label: '50% - 60%'
}]

export const fruitVegs = [
    'Tomatoes',
    'Cabbages',
    'Spinach',
    'Onions',
    'Beans',
    'Green Beans'
];

export const crops = [
    "Wheat",
    "Maize",
    "Paddy",
    "Irish Potatoes",
    "Beans",
    "Cassava",
    "Sunflower",
    "Ground Nuts"
]

export const creditDecision = [{
    value: "FALSE",
    label: "FALSE"
}, {
    value: "TRUE",
    label: "TRUE"
}]