import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { setShowDialog } from '../../slices/showDialog';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 20,
  p: 4,
};

function CreditModal() {
  const noPointer = { cursor: 'pointer' };
  const { showDialog } = useSelector((state) => state.showDialog);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const changeLanguage = (value) => {
    localStorage.setItem('lang', value);
    window.location.reload();
  };

  return (
    <Modal
      data-testid="modal-test-id"
      open={showDialog}
      aria-labelledby="common_modal"
      aria-describedby="common_modal_desc">
      <Box sx={style}>
        <Grid display="flex" justifyContent="space-between" alignItems="center">
          <Typography id="modal-modal-title" variant="h6" component="h6">
            {t('select.language')}
          </Typography>
          <span onClick={() => { dispatch(setShowDialog(!showDialog)) }}><CloseIcon style={noPointer} /></span>
        </Grid>
        <Divider />
        <List mt={3}>
          <ListItem disablePadding selected={localStorage.getItem("lang") === "en"}>
            <ListItemButton onClick={() => changeLanguage("en")}>
              <ListItemIcon>
                <FormatListBulletedIcon />
              </ListItemIcon>
              <ListItemText primary="English" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding selected={localStorage.getItem("lang") === "fr"}>
            <ListItemButton onClick={() => changeLanguage("fr")}>
              <ListItemIcon>
                <FormatListBulletedIcon />
              </ListItemIcon>
              <ListItemText primary="French" />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>

    </Modal>
  );
}

CreditModal.propTypes = {
  showDialog: PropTypes.bool
};

export default CreditModal;
