import React, { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import "./App.css"
import { ThemeProvider } from '@mui/material/styles';
import { THEME } from '../helpers/theme';
import Spinner from '../components/Spinner/Spinner';
import CreditDecision from '../pages/CreditDecision/CreditDecision';
const WelcomePage = lazy(() => import('../pages/WelcomePage/WelcomePage'))
const CreditScore = lazy(() => import('../pages/CreditScore/CreditScore'))
const PageNotFound = lazy(() => import('../pages/PageNotFound/PageNotFound'))

function App() {
  return (
    <ThemeProvider theme={THEME}>
      <Suspense fallback={<Spinner />}>
        <Routes>
          <Route exact path="/" element={<WelcomePage />} />
          <Route exact path='/v1/ke/creditscore' element={<CreditScore />} />
          <Route exact path="/v1/ke/creditdecision" element={<CreditDecision />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
