import { configureStore } from '@reduxjs/toolkit'
import registerReducer from "./slices/register";
import messageReducer from "./slices/message"
import statusCodeReducer from "./slices/statusCode"
import showDialogReducer from "./slices/showDialog"
import updateCreditReducer from './slices/updateCredit';
import fetchCustomerReducer from './slices/fetchCustomer';

const reducer = {
    register: registerReducer,
    message: messageReducer,
    statusCode: statusCodeReducer,
    showDialog: showDialogReducer,
    updateCredit: updateCreditReducer,
    fetchCustomer: fetchCustomerReducer
}

const store = configureStore({
    reducer: reducer,
    devTools: true,
})

export default store;