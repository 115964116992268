import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const statusCodeSlice = createSlice({
    name: "statuscode",
    initialState,
    reducers: {
        setStatusCode: (state, action) => {
            return { statusCode: action.payload };
        }
    },
});

const { reducer, actions } = statusCodeSlice;

export const { setStatusCode } = actions
export default reducer